.tui-modal-container {
  width: 1100px;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tui-modal {
  width: 960px;
  height: 636px;
  position: relative;
  border-radius: 4px;
  background-color: #fff;
}
.tui-modal .tui-body {
  width: 100%;
  height: 421px;
  display: flex;
  justify-content: center;
}
.tui-modal .tui-body .tui-tiles {
  width: 832px;
  height: 100%;
}
.tui-modal .tui-body .tui-tiles .tui-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.tui-modal .tui-body .tui-tiles .tui-tile.tui-tile-hidden {
  height: 0px;
  display: hidden;
  border: none;
}
.tui-modal .tui-body .tui-tiles .tui-tile.tui-tile-selected {
  border: solid 0.5px #3e8f00;
}
.tui-modal .tui-body .tui-tiles .tui-tile {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 193px;
  height: 127px;
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 4px;
  border: solid 0.5px #a5a8ab;
}
.tui-modal .tui-body .tui-tiles .tui-tile :nth-child(n+9) .tui-tile-background {
  border: solid 0.5px #a5a8ab;
}
.tui-modal .tui-body .tui-tiles .tui-tile .tui-tile-header {
  position: relative;
  height: 35px;
  display: flex;
  align-items: center;
}
.tui-modal .tui-body .tui-tiles .tui-tile .tui-tile-header.tui-tile-header-selected {
  background-color: #eafcde;
  border-radius-top-left: 4px;
  border-radius-top-right: 4px;
}
.tui-modal .tui-body .tui-tiles .tui-tile .tui-tile-header .tui-tile-header-label {
  width: 153px;
  height: 28px;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: #333;
  display: flex;
  align-items: center;
  position: relative;
  left: 10px;
}
.tui-modal .tui-body .tui-tiles .tui-tile .tui-tile-header .tui-tile-header-label.tui-tile-header-label-selected {
  color: #317503;
}
.tui-modal .tui-body .tui-tiles .tui-tile .tui-tile-header .tui-tile-header-label span {
  text-transform: capitalize;
}
.tui-modal .tui-body .tui-tiles .tui-tile .tui-tile-header .tui-symbol {
  position: relative;
  left: 18.5px;
  color: #3e8f00;
}
.tui-modal .tui-body .tui-tiles .tui-tile .tui-tile-header .tui-symbol.tui-symbol-selected {
  color: #317503;
}
.tui-modal .tui-body .tui-tiles .tui-tile .tui-tile-header .tui-symbol .icon-plus-no-circle:before {
  font-weight: bold;
}
.tui-modal .tui-body .tui-tiles .tui-tile:hover .tui-tile-background,
.tui-modal .tui-body .tui-tiles .tui-tile:focus .tui-tile-background {
  transform: scale(1.2, 1.2);
}
.tui-modal .tui-body .tui-tiles .tui-tile .tui-tile-background {
  background-size: cover;
  background-position: center;
  height: 108px;
  border-radius: 0px 0px 5px 5px;
  transition: all 0.5s;
}
.tui-footer {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tui-button {
  cursor: pointer;
  width: 221px;
  height: 38px;
  border-radius: 2px;
  background-color: #3e8f00;
  border: solid 1px #3e8f00;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tui-button.tui-button-hidden {
  background-color: #fff;
  border: none;
}
.tui-button .tui-button-text {
  height: 19px;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.tui-button .tui-button-text.tui-button-text-standalone {
  color: #75787b;
}
.tui-button .tui-button-text:first-letter {
  text-transform: capitalize;
}
.tui-button.tui-button-done {
  position: absolute;
  top: 327px;
  left: 314.5px;
}
.tui-modal-done {
  width: 850px;
  height: 433px;
  position: relative;
  display: flex;
  justify-content: center;
  -webkit-transition: width 1s, height 1s;
  transition: width 1s, height 1s;
}
.tui-dismiss-x {
  width: 15.3px;
  height: 15.3px;
  position: absolute;
  top: 24.9px;
  left: 918.1px;
  cursor: pointer;
}
.tui-dismiss-x .icon-x {
  color: #a5a8ab;
}
.tui-dismiss-x.tui-dismiss-x-done {
  width: 16px;
  height: 17px;
  position: absolute;
  top: 17px;
  left: 817px;
}
.tui-dismiss-x.tui-dismiss-x-continue-shopping {
  width: 16px;
  height: 17px;
  position: absolute;
  top: 17px;
  left: 817px;
}
.tui-done-icon {
  position: absolute;
  width: 64px;
  height: 64px;
  top: 62px;
  left: 393px;
  font-size: 64px;
  color: #3e8f00;
}
.tui-header {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
}
.tui-header.tui-header-done {
  position: absolute;
  top: 167px;
}
.tui-header.tui-header-done span {
  height: 56px;
  font-family: OpenSans;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.tui-header .tui-header-text {
  width: 807.2px;
  height: 33px;
  font-family: OpenSans;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  position: relative;
  top: 23px;
}
.tui-header .tui-header-text.tui-header-text-done {
  height: 56px;
  font-size: 21px;
  font-weight: normal;
}
.tui-done-header-1 {
  position: absolute;
  top: 167px;
}
.tui-done-header-1 span {
  height: 56px;
  font-family: OpenSans;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.tui-done-header-2 {
  position: absolute;
  top: 208px;
}
.tui-done-header-2 span {
  width: 550px;
  height: 42px;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #75787b;
}
.tui-done-button {
  width: 221px;
  height: 40px;
  border-radius: 2px;
  background-color: #3e8f00;
  position: absolute;
  top: 327px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tui-done-button span {
  height: 32px;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.29;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.tui-subheader {
  width: 100%;
  height: 39px;
  display: flex;
  justify-content: center;
}
.tui-subheader.tui-subheader-done {
  position: absolute;
  top: 208px;
}
.tui-subheader.tui-subheader-done span {
  width: 550px;
  height: 42px;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #75787b;
}
.tui-subheader .tui-subheader-text {
  height: 20px;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333;
  text-transform: uppercase;
  position: relative;
  top: 10px;
}
.tui-subheader .tui-subheader-text.tui-subheader-text-done {
  width: 550px;
  height: 42px;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
}
.tui-subheader .tui-subheader-text.tui-subheader-text-done color #75787b {
  text-transform: lower;
}
.tui-single-page {
  width: 1063px;
}
.tui-single-page .tui-single-page-header {
  width: 100%;
}
.tui-single-page .tui-single-page-header .tui-single-page-header-text {
  height: 24px;
  font-family: OpenSans;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 18px;
  text-transform: capitalize;
  color: #35383b;
}
.tui-single-page .tui-single-page-subheader-text {
  width: 457px;
  height: 20px;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 12px;
  text-transform: capitalize;
  color: #75787b;
}
.tui-single-page .tui-body {
  justify-content: left;
}
.tui-single-page .tui-body .tui-tiles {
  height: 100%;
}
.tui-single-page .tui-body .tui-tiles .tui-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.tui-single-page .tui-body .tui-tiles .tui-tile.tui-tile-hidden {
  height: 0px;
  display: hidden;
  border: none;
}
.tui-single-page .tui-body .tui-tiles .tui-tile.tui-tile-selected {
  border: solid 0.5px #3e8f00;
}
.tui-single-page .tui-body .tui-tiles .tui-tile {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 235px;
  height: 165.9px;
  border-radius: 4px;
  border: solid 0.5px #a5a8ab;
  overflow: hidden;
  margin-bottom: 30.7px;
}
.tui-single-page .tui-body .tui-tiles .tui-tile :nth-child(n+9) .tui-tile-background {
  border: solid 0.5px #a5a8ab;
}
.tui-single-page .tui-body .tui-tiles .tui-tile:hover .tui-tile-background,
.tui-single-page .tui-body .tui-tiles .tui-tile:focus .tui-tile-background {
  transform: scale(1.2, 1.2);
}
.tui-single-page .tui-body .tui-tiles .tui-tile .tui-tile-background {
  background-size: cover;
  background-position: bottom;
  height: 149px;
  border-radius: 0px 0px 5px 5px;
  transition: all 0.5s;
}
.tui-single-page .tui-body .tui-tiles .tui-tile .tui-tile-header {
  position: relative;
  height: 45px;
  display: flex;
  align-items: center;
}
.tui-single-page .tui-body .tui-tiles .tui-tile .tui-tile-header.tui-tile-header-selected {
  background-color: #eafcde;
  border-radius-top-left: 4px;
  border-radius-top-right: 4px;
}
.tui-single-page .tui-body .tui-tiles .tui-tile .tui-tile-header .tui-tile-header-label {
  width: 185px;
  height: 28px;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: #333;
  display: flex;
  align-items: center;
  position: relative;
  left: 11px;
}
.tui-single-page .tui-body .tui-tiles .tui-tile .tui-tile-header .tui-tile-header-label.tui-tile-header-label-selected {
  color: #317503;
}
.tui-single-page .tui-body .tui-tiles .tui-tile .tui-tile-header .tui-tile-header-label span {
  text-transform: capitalize;
}
.tui-single-page .tui-body .tui-tiles .tui-tile .tui-tile-header .tui-symbol {
  position: relative;
  left: 22.5px;
  color: #3e8f00;
  font-weight: bold;
}
.tui-single-page .tui-body .tui-tiles .tui-tile .tui-tile-header .tui-symbol.tui-symbol-selected {
  color: #317503;
}
.tui-single-page .tui-body .tui-tiles .tui-tile .tui-tile-header .tui-symbol .icon-plus-no-circle:before {
  font-weight: bold;
}
#taxonomy-preferences-modal {
  padding: 10px 12px;
  height: auto;
}
#taxonomy-preferences-modal .modal-body {
  height: auto;
  width: auto;
  display: flex;
  padding: 0px;
  box-shadow: none;
}
#taxonomy-preferences-modal .modal-body .preview {
  height: 100%;
}
/*# sourceMappingURL=modules/home/home_desktop_taxonomy_lazy_load.entry.css.map */